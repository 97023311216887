<template>
    <div class="contact-container">
        <div class="ontact-top container-width">
            <img
                    src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/lianxi.png"
            />
        </div>
        <div style="max-width:1420px;margin: 0 auto;margin-top: 40px;margin-bottom: 10px" class="conK">
            <div
                    class="ontact-tops  successM successS" style="display: inline-block;margin-right: 90px"
            >
                <img
                        src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/map.png"
                />
            </div>
            <div class="ontact-hand  successM" style=" position: relative;display: inline-block;vertical-align: top">

                <div>
                    <img src="../../assets/images/ContactUs/dianhua.png" class="imgH"/>
                    <span>招商电话：0371-68187777</span>
                </div>
                <div>
                    <img
                            src="../../assets/images/ContactUs/youxaing.png"
                            style="height: 17px"
                    />
                    <span style="vertical-align: 0.05em">邮箱：zztjzx@163.com</span>
                </div>
                <div>
                    <img
                            src="../../assets/images/ContactUs/weizhi.png"
                            style="height: 25px"
                    />
                    <span style="vertical-align: 0.2em"
                    >园区地址：郑州市二七区公安路和月光路的交叉口附近</span
                    >
                </div>
                <div class="erwei">
                    <img src="../../assets/images/erweima.png"/>
                    <div class="title">扫码关注我们</div>
                </div>


            </div>

        </div>


    </div>
</template>

<script>
    export default {};
</script>

<style scoped>



    .ontact-tops img {
        width: 572px;
        border-radius: 10px;
        box-shadow: 0 2px 4px  rgba(0, 0, 0, .1);
    }

    .ontact-top img {
        width: 100%;
    }

    .ontact-hand {

    }

    .ontact-hand div {
        margin-bottom: 28px;

    }

    .erwei {
        width: 100px;
        left: 570px;
    }

    .ontact-hand img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .erwei img {
        width: 100px;
        height: 100px;
    }

    .erwei .title {
        font-size: 16px;
        color: #999999;
    }

    .ontact-hand span {
        font-size: 20px;
        color: #000000;
        vertical-align: 0.1em;
    }

    @media screen and (max-width: 1190px) {
        .successM {

        }
        .successS{
            margin-right: 30px !important;
        }


    }
    @media screen and (max-width: 1500px){
        .conK{
            padding-left: 30px;
        }
    }
</style>
